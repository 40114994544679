@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
:root {
  --primary: #43BB80;
  --secondary: #002C6C;
}

* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.5;
  font-family: "MontSerrat";
  -webkit-font-smoothing: antialiased;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

app-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
}

.Input {
  background-color: #FFFFFF;
  border: 2px solid #DEE5EF;
  border-radius: 8px;
  padding: 15.5px 0 15.5px 17px;
  color: #333;
  font-weight: 500;
  font-family: "Montserrat";
}
.Input::placeholder {
  color: #878D95;
}
.Input:focus-visible {
  border: 1px solid #43BB80;
  outline: none;
}
.Input:focus {
  border: 1px solid #43BB80;
  outline: none;
}

.Disabled {
  background-color: #DEE5EF;
}

input[type=date] {
  font-family: Montserrat;
  padding-right: 20px;
  font-weight: 500;
}
input[type=date]::placeholder {
  color: #878D95;
}

/* Adiciona um ícone do calendário personalizado */
.Error {
  border: 2px solid #E4373D;
}

.ErrorMessage, .field-error, .field__error {
  font-size: 12px;
  color: #E4373D;
  font-weight: 500;
}

.Button {
  font-family: "MontSerrat";
  font-size: 17px;
  padding: 15px 22px;
  height: 48px;
  color: #fff;
  background-color: #002C6C;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 600;
}

.Button:hover {
  background-color: #001F4F; /* Cor de fundo ao passar o mouse */
}

.Button:active {
  background-color: #001534; /* Cor de fundo ao clicar */
}

.Button--disabled, .Button:disabled {
  cursor: not-allowed;
  background-color: #DEE5EF;
}

.Button.Button--outline {
  border: 2px solid #002C6C;
  background: none;
  color: #002C6C;
}
.Button.Button--outline:hover {
  background-color: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}